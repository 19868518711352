<template>
    <div id="page-member-creating">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="{ name: 'members' }">Back to Members</router-link>

                    <h1 class="heading">Add Member</h1>

                    <div class="board">
                        <app-loader v-if="loading" />

                        <div class="personal">
                            <h3>Personal information</h3>

                            <app-error v-model="errors.show" :message="errors.message" />

                            <div class="doubled">
                                <app-input
                                    v-model="member.firstname"

                                    label="First Name"

                                    :required="true"

                                    :error="errors.fields.firstname"

                                    @change="errors.fields.firstname = null"
                                    @onenter="confirm"
                                />

                                <app-input
                                    v-model="member.lastname"

                                    label="Last Name"

                                    :required="true"

                                    :error="errors.fields.lastname"

                                    @change="errors.fields.lastname = null"
                                    @onenter="confirm"
                                />
                            </div>

                            <div class="doubled">
                                <app-input
                                    v-model="member.email"

                                    type="email"

                                    label="Email"

                                    :required="true"

                                    :error="errors.fields.email"

                                    @change="errors.fields.email = null"
                                    @onenter="confirm"
                                />

                                <app-input
                                    v-model="member.password"

                                    type="password"

                                    label="Password"

                                    :required="true"

                                    :with-toggle-password-visibility="true"
                                    :password-helper="true"

                                    :error="errors.fields.password"

                                    @change="errors.fields.password = null"
                                    @onenter="confirm"
                                />
                            </div>

                            <div class="doubled">
                                <app-input
                                    v-model="member.mobile"

                                    type="tel"

                                    label="Mobile"

                                    :required="true"

                                    :error="errors.fields.mobile"

                                    @change="errors.fields.mobile = null"
                                    @onenter="confirm"
                                />
                            </div>
                        </div>

                        <div class="teams">
                            <h3>Please select which team(s) to add to this member</h3>

                            <ul v-if="is_member_teams_avaliable" class="teams-list">
                                <li v-for="(team, key) in member_teams" :key="key">
                                    <span class="team-name">{{ team.name }}</span>

                                    <div class="actions">
                                        <button class="btn btn-table-action action-secondary action-remove" @click="removeTeam(team)"></button>
                                    </div>
                                </li>
                            </ul>

                            <form v-if="is_teams_addable_avaliable" @submit.prevent="addTeam">
                                <app-dropdown-select
                                    v-model="team.uuid"

                                    :options="teams_addable"

                                    label="Select team"

                                    :required="!has_at_least_one_team_added"

                                    key-value="UUID"
                                    key-title="Name"
                                />

                                <button class="btn btn-primary" type="submit" :disabled="!team.uuid">Add</button>
                            </form>

                            <button class="btn btn-secondary" @click="confirm" :disabled="!are_required_fields_filled">Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appDropdownSelect from '@/components/app-dropdown-select'

import errMessage from '@/helpers/errMessage'
import UserDetailsValidator from '@/validators/user-details-validator'

export default {
    components: {
        appLoader,
        appError,
        appInput,
        appDropdownSelect,
    },

    data() {
        return {
            member: {
                firstname: null,
                lastname:  null,
                email:     null,
                password:  null,
                mobile:    null,
            },

            member_teams: {},

            team: {
                uuid: null,
            },

            errors: {
                show: false,
                message: '',

                fields: {},
            },

            loading: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.loadTeams()
        },

        loadTeams() {
            this.loading = true

            this.$store.dispatch('getTeamsAll')
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)

                    this.loading = false
                })
        },

        addTeam() {
            if (this.team.uuid && !(this.team.uuid in this.member_teams)) {
                this.$set(this.member_teams, this.team.uuid, {
                    uuid: this.team.uuid,
                    name: this.teams[ this.team.uuid ].Name,
                })

                this.team = {
                    uuid: null,
                }
            }
        },

        removeTeam(team) {
            if (team.uuid in this.member_teams) {
                this.$delete(this.member_teams, team.uuid)
            }
        },

        addMemberToTeam(AuthRoleGroupUUID, AuthID) {
            const payload = {
                AuthRoleGroupUUID,

                member: {
                    SPID: this.current_spid,
                    AuthRoleGroupUUID,
                    IdentityProvider: this.identity_provider,
                    AuthID,
                },
            }

            return this.$store.dispatch('addMemberToTeam', payload)
                .then(response => {
                    return Promise.resolve(response)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },

        confirm() {
            if (this.validation()) {
                this.loading = true

                const member = {
                    SPID:         this.current_spid,
                    Username:     this.member.email,
                    Email:        this.member.email,
                    Password:     this.member.password,
                    MobileNumber: this.member.mobile,
                    FirstName:    this.member.firstname,
                    LastName:     this.member.lastname,
                }

                this.$store.dispatch('createMember', { member })
                    .then(({ UUID, Email }) => {
                        const teams = Object.keys(this.member_teams)

                        const promises = []

                        for (let i = 0, len = teams.length; i < len; i++) {
                            promises.push(this.addMemberToTeam(teams[i], UUID))
                        }

                        Promise.all(promises)
                            .then(() => {
                                this.loading = false

                                this.$store.dispatch('addToast', {
                                    message: `Member ${ Email } has been added successfully`,
                                    delay: 5000,
                                })

                                this.$router.push({ name: 'members' })
                            })
                            .catch(error => {
                                this.errors.show = true
                                this.errors.message = errMessage(error)

                                this.loading = false
                            })
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.loading = false
                    })
            }
        },

        validation() {
            let is_valid = true

            this.errors.fields = {}

            const fields = {
                firstname: { rule: 'firstname', value: this.member.firstname, message: 'Please, enter valid first name'    },
                lastname:  { rule: 'lastname',  value: this.member.lastname,  message: 'Please, enter valid last name'     },
                email:     { rule: 'email',     value: this.member.email,     message: 'Please, enter valid email address' },
                password:  { rule: 'passnew',   value: this.member.password,  message: 'Please, enter password'            },
                mobile:    { rule: 'mobile',    value: this.member.mobile,    message: 'Please, enter valid mobile number' },
            }

            for (const key in fields) {
                if (UserDetailsValidator.isRuleExists(fields[key].rule)) {
                    if (UserDetailsValidator.isInvalid(fields[key].rule, fields[key].value, fields[key].message)) {
                        this.errors.fields[ key ] = UserDetailsValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'identity_provider',
            'teams',
        ]),

        is_teams_loaded() {
            return this.teams && Object.keys(this.teams).length > 0
        },

        is_member_teams_avaliable() {
            return this.is_teams_loaded && Object.keys(this.member_teams).length > 0
        },

        teams_addable() {
            let teams_addable = {...this.teams}

            for (const uuid in this.member_teams) {
                delete teams_addable[ uuid ]
            }

            return teams_addable
        },

        is_teams_addable_avaliable() {
            return Object.keys(this.teams_addable).length > 0
        },

        has_at_least_one_team_added() {
            return Object.keys(this.member_teams).length > 0
        },

        are_required_fields_filled() {
            return this.member.firstname
                && this.member.lastname
                && this.member.email
                && this.member.password
                && this.member.mobile
                && this.has_at_least_one_team_added
        },
    },
}
</script>

<style lang="scss">
#page-member-creating {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        max-width: 770px;

        margin-bottom: 24px;
    }

    .board {
        position: relative;

        padding: 24px;

        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);

        .app-loader {
            border-radius: $border-radius-secondary;
        }

        h3 {
            font-size: 20px;
            line-height: 22px;
        }

        .doubled {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
        }
    }

    .personal {
        .app-input {
            width: 370px;
            margin: 24px 30px 0 0;

            &:last-child {
                margin-right: 0;
            }
        }

        .btn-confirm {
            margin-top: 24px;
        }
    }

    .teams {
        margin-top: 24px;

        h3 {
            margin-bottom: 24px;
        }

        .teams-list {
            max-width: 370px;
        }

        ul {
            max-width: 770px;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px 0;
                border-bottom: 1px solid var(--color-divider);

                :first-child {
                    flex-grow: 1;
                }

                .actions {
                    flex-shrink: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-left: 24px;

                    .btn {
                        margin-left: 24px;

                        &:first-child { margin: 0; }
                    }
                }

                &:first-child {
                    padding-top: 0;
                }
            }

            &:last-child {
                li {
                    &:last-child {
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }

            &:empty {
                display: none;
            }

            &+form {
                margin-top: 30px;
            }
        }

        form {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            margin-top: 24px;
            position: relative;

            .app-dropdown-select {
                width: 370px;
                margin: 0 30px 24px 0;

                &:last-child {
                    margin-right: 0;
                }
            }

            .btn {
                margin-bottom: 24px;

                &:after {
                    content: "team";
                    padding-left: 5px;
                }
            }
        }

        .btn {
            max-width: 170px;
        }
    }
}

@media (max-width: $tablet-size) {
    #page-member-creating {
        .personal {
            .app-input {
                width: 258px;
            }
        }

        .teams {
            h3 {
                margin-bottom: 16px;
            }

            ul {
                max-width: 546px;

                li {
                    .actions {
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-member-creating {
        margin: 24px 0 64px;

        .btn-back-to {
            margin-bottom: 8px;

            font-size: 16px;
            line-height: 18px;
        }

        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .board {
            h3 {
                font-size: 16px;
            }

            .doubled {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

       .personal {
           .app-input {
               width: 100%;
               margin-right: 0;
           }
        }

        .teams {
            margin-top: 32px;

            font-size: 14px;
            line-height: 24px;

            h3 {
                margin-bottom: 16px;
            }

            .teams-list {
                max-width: 100%;
            }

            ul {
                li {
                    padding: 8px 0;

                    .actions {
                        .btn {
                            margin-left: 16px;
                        }
                    }
                }

                &+form {
                    margin-top: 24px;
                }
            }

            form {
                flex-wrap: wrap;
                justify-content: space-between;

                .app-dropdown-select {
                    width: calc(100% - 24px - 48px);
                    margin-right: 0;

                    &:first-child {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>